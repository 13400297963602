import React, { useState, useEffect, useCallback, useMemo } from "react";
import { gql, useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { startOfMonth, endOfMonth, subMonths, format } from "date-fns";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ja } from "date-fns/locale";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { ALL_VENUES, Reservation } from "./types";
import VenueSelector from "./VenueSelector";
import Overview from "./Overview";
import ProductOverview from "./ProductOverview";
import Totals from "./Totals";

const currentMonth = startOfMonth(new Date());

type ReservationsQueryResult = {
  venueReservations: {
    totalCount: number;
    records: Reservation[];
  };
};

export default function VenueCosts() {
  const [offset] = React.useState(0);
  const [limit] = React.useState(1000);
  const [yearMonth, setYearMonth] = useState<Date>(currentMonth);
  const [venue, setVenue] = useState<Venue | undefined>(undefined);
  const [
    reservationsWithProductOrders,
    setReservationsWithProductOrders,
  ] = useState<Reservation[]>([]);

  // cost data
  const [
    getCostData,
    { loading: costLoading, error: costError, data: costData },
  ] = useLazyQuery<ReservationsQueryResult>(GET_VENUE_RESERVATIONS, {
    variables: {
      offset,
      limit,
      from: yearMonth ? format(yearMonth, "yyyy-MM-dd") : null,
      until: yearMonth ? format(endOfMonth(yearMonth), "yyyy-MM-dd") : null,
      venueId: venue?.id,
    },
  });

  useEffect(() => {
    if (costData && costData.venueReservations.records.length > 0) {
      setReservationsWithProductOrders(
        costData.venueReservations.records.filter((record) => {
          return record.booking.productOrders.length > 0;
        })
      );
    }
  }, [costData]);

  // venue data
  const { error: venuesError, data: venuesData } = useQuery<venueQueryResponse>(
    GET_VENUES,
    {
      onCompleted: (data) => {
        if (!venue) {
          const venues = data.venues;
          const venue =
            venues.length > 1
              ? { id: ALL_VENUES, name: ALL_VENUES }
              : venues[0];
          setVenue(venue);
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const venues = useMemo(() => {
    return venuesData ? venuesData.venues : [];
  }, [venuesData]);

  // pdf data
  const apolloClient = useApolloClient();

  const getPdfData = useCallback(async () => {
    const res = await apolloClient.query({
      query: GET_INVOICE_PDF,
      variables: {
        input: {
          month: format(yearMonth, "yyyy-MM-dd"),
          venueId: venue?.id,
        },
      },
    });

    return res.data.fetchOrGenerateInvoicePdf as string;
  }, [yearMonth, venue, apolloClient]);

  const reservations = useMemo(
    () =>
      (costData?.venueReservations.records ?? []).map((reservation) => ({
        ...reservation,
        status:
          reservation.booking.status === "CANCELLED"
            ? "CANCELLED"
            : reservation.status,
      })),
    [costData]
  );

  for (const error of [costError, venuesError])
    if (error) return <>Error! {error.message}</>;

  return (
    <>
      <Card sx={{ marginBottom: 1.5 }}>
        <CardHeader
          titleTypographyProps={{
            textAlign: "center",
          }}
          title={"実績詳細画⾯"}
        />
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <LocalizationProvider locale={ja} dateAdapter={AdapterDateFns}>
              <DatePicker
                renderInput={(props) => <TextField {...props} />}
                openTo="month"
                minDate={subMonths(currentMonth, 24)}
                views={["year", "month"]}
                label="年と月を選択してください。"
                value={yearMonth}
                onChange={(newValue) => {
                  setYearMonth(new Date(newValue!));
                }}
              />
            </LocalizationProvider>
            <VenueSelector venue={venue} setVenue={setVenue} venues={venues} />
            <LoadingButton
              sx={{
                width: "8em",
                color: "#ffffff",
                backgroundColor: "#c8a063",
                margin: "1em",
                "&:hover": {
                  backgroundColor: "#a37939",
                },
              }}
              loading={costLoading}
              loadingIndicator={<CircularProgress />}
              variant="contained"
              size="large"
              onClick={() => getCostData()}
            >
              表示
            </LoadingButton>
          </Grid>
        </CardContent>
      </Card>
      {costData && reservations && (
        <>
          <Totals reservations={reservations} />
          <Overview
            reservations={reservations}
            totalCount={costData.venueReservations.totalCount}
            yearMonth={format(yearMonth, "yyyy-MM-dd")}
            venue={venue}
            getPdfData={getPdfData}
          />
        </>
      )}
      {reservationsWithProductOrders &&
        reservationsWithProductOrders.length > 0 && (
          <>
            <ProductOverview
              reservations={reservationsWithProductOrders}
              totalCount={reservationsWithProductOrders.length}
              yearMonth={format(yearMonth, "yyyy-MM-dd")}
              venue={venue}
              getPdfData={getPdfData}
            />
          </>
        )}
    </>
  );
}

const GET_VENUE_RESERVATIONS = gql`
  query VenueReservationsListQuery(
    $offset: Int!
    $limit: Int!
    $from: String
    $until: String
    $venueId: String
  ) {
    venueReservations(
      offset: $offset
      limit: $limit
      from: $from
      until: $until
      venueId: $venueId
    ) {
      records {
        id
        status
        datetime
        createdAt
        cancelledAt
        commissionRate
        venuePaymentRate
        cancellationRate
        items {
          id
          quantity
          costsCurrencyCode
          priceType {
            name
            unitType
            amountInMinorUnits
            amount
            contractedPriceInMinorUnits
            contractedPrice
            currencyCode
          }
        }
        booking {
          id
          familyName
          familyNameFurigana
          givenNameFurigana
          givenName
          status
          noShow
          paymentStatus
          paymentAmount
          paymentCurrency
          planSnapshot {
            name
            activities {
              id
              name
              priceTypes {
                id
                name
                unitType
                amountInMinorUnits
                amount
              }
            }
          }
          payment {
            id
            bookingId
            method
          }
          productOrders {
            id
            quantity
            status
            price
            product {
              name
              overrideName
              sellingPrice
              template {
                displayName
                overrideDisplayName
                commissionRate
              }
            }
          }
        }
        activity {
          id
          commissionRate
          onSiteCommissionRate
          venue {
            id
            name
          }
          plan {
            id
            name
          }
        }
        costs {
          commissionRate
          onSiteCommissionRate
          venuePaymentRate
          contractedAmount
          commission
          onSiteCommission
          venuePaymentAmount
          currencyCode
          cancellationFee
          productOrderCost
          productOrderCommissionRate
          productOrderCommissionFee
        }
      }
      totalCount
    }
  }
`;

const GET_INVOICE_PDF = gql`
  query GetInvoicePdf($input: FetchOrGenerateInvoicePdfInput!) {
    fetchOrGenerateInvoicePdf(input: $input)
  }
`;

const GET_VENUES = gql`
  query GetVenues {
    venues {
      id
      name
    }
  }
`;

export type Venue = { id: string; name: string };
interface venueQueryResponse {
  venues: Venue[];
}
