import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

import { RenderParams } from "./types";
import reservationStatusColors from "../../../helpers/booking-status-colors";
import reservationStatusTranslations from "../../../helpers/booking-status-translations";
import paymentMethodTranslation from "../../../helpers/payment-method-translation";
import itemOrderStatusTranslations from "../../../helpers/item-order-status-translations";
import { isProductionEnvironment } from "../../../helpers/environment";

export function renderViewBooking(params: RenderParams) {
  return (
    <>
      <Tooltip title={params.row.booking.id + "予約詳細を見る"}>
        <Link
          href={`/bookings/${params.row.id}`}
          target="_blank"
          rel="noreferrer"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.booking.id}
        </Link>
      </Tooltip>
    </>
  );
}

export function renderStatus(params: RenderParams) {
  return (
    <span
      style={{
        color: reservationStatusColors[params.row.status],
        width: "100%",
        textAlign: "center",
      }}
    >
      {reservationStatusTranslations[params.row.status]}
    </span>
  );
}

export function renderPlan(params: RenderParams) {
  const storefrontUrl = isProductionEnvironment
    ? "https://oiwai.anny.gift"
    : "https://staging-oiwai.anny.gift";
  return (
    <Tooltip title={params.row.activity.plan.name}>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <a
          style={{ color: "#000" }}
          target="_blank"
          rel="noreferrer"
          href={`${storefrontUrl}/celebration-plans/${params.row.activity.plan.id}`}
        >
          {params.row.activity.plan.name}
        </a>
      </div>
    </Tooltip>
  );
}

export function renderActivity(params: RenderParams) {
  const activity = params.row.booking?.planSnapshot?.activities?.find(
    (a) => a.id === params.row.activity.id
  );

  return (
    <Tooltip
      title={
        activity
          ? (activity.name as string)
          : (params.row.activity.name as string)
      }
    >
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {activity?.name ?? params.row.activity.name}
      </div>
    </Tooltip>
  );
}

export function renderCustomer(params: RenderParams) {
  return (
    <div>
      {params.row.booking.familyName} {params.row.booking.givenName} 様<br />
      {params.row.booking.familyNameFurigana}
      {params.row.booking.givenNameFurigana}
    </div>
  );
}

export function renderCreatedAt(params: RenderParams) {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {params.row.createdAt.substr(0, 10)}
      <br />
      {params.row.createdAt.substr(10, 6)}
    </div>
  );
}

export function renderDatetime(params: RenderParams) {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {params.row.datetime.substr(0, 10)}
      <br />
      {params.row.datetime.substr(10, 6)}
    </div>
  );
}

export function renderItems(params: RenderParams) {
  const items = params.row.items.reduce((init, item) => {
    if (item.priceType.name.includes("大人") && item.quantity > 0) {
      return init + Number(item.quantity);
    }
    return init;
  }, 0);

  return (
    <Tooltip title={items}>
      <div>{items}</div>
    </Tooltip>
  );
}

export function renderItemsNotAdult(params: RenderParams) {
  const items = params.row.items
    .map((i, index) => {
      if (!i.priceType.name.includes("大人") && i.quantity > 0) {
        return (
          <span key={index}>
            {i.priceType.name}: {i.quantity}
            <br />
          </span>
        );
      }
      return null;
    })
    .filter((c) => Boolean(c));

  return (
    <Tooltip title={items}>
      <div>{items.length > 0 ? items : "なし"}</div>
    </Tooltip>
  );
}

export function renderTemplate(params: RenderParams) {
  if (params.row.booking.productOrders.length == 0) {
    return <span>なし</span>;
  }

  const products = params.row.booking.productOrders.map((p, index) => {
    return (
      <span key={index}>
        {p.product.template.displayName} {p.product.name} {p.id}{" "}
        {p.quantity * (p.price ?? 0) + "円"}
        <br />
      </span>
    );
  });

  return (
    <Tooltip title={products}>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {products}
      </div>
    </Tooltip>
  );
}

export function renderPaymentMethod(params: RenderParams) {
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {paymentMethodTranslation[params.row.booking.payment.method]}
    </div>
  );
}

export function renderPayment(params: RenderParams) {
  return (
    <span
      style={{
        textDecoration:
          params.row.status !== "RESERVED" || params.row.booking.noShow
            ? "line-through"
            : "none",
        color:
          params.row.status !== "RESERVED" || params.row.booking.noShow
            ? "red"
            : "",
      }}
    >
      {params.row.booking.paymentAmount +
        " " +
        params.row.booking.paymentCurrency}
    </span>
  );
}

export function renderPaymentEmptyProduct(params: RenderParams) {
  const productPrice = params.row.booking.productOrders.reduce((init, p) => {
    return init + p.quantity * (p.price ?? 0);
  }, 0);
  return (
    <div>
      {params.row.booking.paymentAmount -
        productPrice +
        " " +
        params.row.booking.paymentCurrency}
    </div>
  );
}

export function renderPaymentProduct(params: RenderParams) {
  const productPrice = params.row.booking.productOrders.reduce((init, p) => {
    return init + p.quantity * (p.price ?? 0);
  }, 0);
  return <div>{productPrice} JPY</div>;
}

export function renderCancellation(params: RenderParams) {
  return (
    <div>
      {`${params.row.costs.cancellationFee} ${params.row.costs.currencyCode}`}
    </div>
  );
}

export function renderCommissionRate(params: RenderParams) {
  const commissionRate =
    params.row.costs[
    params.row.booking.payment.method === "ON_SITE_PAYMENT"
      ? "onSiteCommissionRate"
      : "commissionRate"
    ];
  return <div>{`${commissionRate} %`}</div>;
}

export function renderCommissionFee(params: RenderParams) {
  const { booking, costs, status } = params.row;

  const commissionRate =
    costs.onSiteCommissionRate || costs.commissionRate || 0;

  const commission = Math.round(
    status === "CANCELLED"
      ? costs.cancellationFee * (commissionRate / 100)
      : costs[
      booking.payment.method === "ON_SITE_PAYMENT"
        ? "onSiteCommission"
        : "commission"
      ]
  );

  return <div
    style={{
      color: params.row.booking.noShow ? "red" : "",
      textDecoration: params.row.booking.noShow ? "line-through" : "",
    }}
  >{`${commission} ${costs.currencyCode}`}</div>;
}

export function renderProductOrderStatus(params: RenderParams) {
  const status = params.row.booking.productOrders.map((po, index) => (
    <span key={index}>
      {itemOrderStatusTranslations[po.status]}
      <br />
    </span>
  ));
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {status}
    </div>
  );
}

export function renderProductOrderQuantity(params: RenderParams) {
  const quantities = params.row.booking.productOrders.map((po, index) => (
    <span key={index}>
      {po.quantity}
      <br />
    </span>
  ));
  return <div>{quantities}</div>;
}

export function renderProductOrderName(params: RenderParams) {
  const products = params.row.booking.productOrders.map((i, index) => (
    <span key={index}>
      {i.product?.template?.overrideDisplayName ??
        i.product?.template?.displayName}
      : {i.product.overrideName ?? i.product.name}
      <br />
    </span>
  ));

  return (
    <Tooltip title={products}>
      <div>{products}</div>
    </Tooltip>
  );
}

export function renderProductOrderSellingPrice(params: RenderParams) {
  const prices = params.row.booking.productOrders.map((i, index) => (
    <span key={index}>
      {i.price ?? i.product.sellingPrice} {params.row.costs.currencyCode}
      <br />
    </span>
  ));
  return <div>{prices}</div>;
}

const renders: {
  [key: string]: (
    params: RenderParams,
    ...props: any
  ) => JSX.Element | undefined;
} = {
  id: renderViewBooking,
  status: renderStatus,
  plan: renderPlan,
  activity: renderActivity,
  customer: renderCustomer,
  createdAt: renderCreatedAt,
  datetime: renderDatetime,
  items: renderItems,
  template: renderTemplate,
  itemsNotAdult: renderItemsNotAdult,
  paymentMethod: renderPaymentMethod,
  payment: renderPayment,
  paymentEmptyProduct: renderPaymentEmptyProduct,
  paymentProduct: renderPaymentProduct,
  cancellation: renderCancellation,
  commissionRate: renderCommissionRate,
  commissionFee: renderCommissionFee,
  productStatus: renderProductOrderStatus,
  productQuantity: renderProductOrderQuantity,
  productName: renderProductOrderName,
  productPrice: renderProductOrderSellingPrice,
};

export default renders;
